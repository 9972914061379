import {useContext, useEffect, useMemo, useState} from "react";
import {get} from "../axios";
import LanguageContext from "../../../LanguageContext";

const useFetchAllData = () => {
    const {currentLanguage} = useContext(LanguageContext);
    const [testimonials, setTestimonials] = useState([]);
    const [articles, setArticles] = useState([]);
    const [slides, setSlides] = useState([]);
    const [services, setServices] = useState([]);
    const [serviceOptions, setServiceOption] = useState([]);
    const [homeIsLoading, setHomeIsLoading] = useState(true);
    const [generalData, setGeneralData] = useState([]);
    const [articleFeatured, setArticleFeatured] = useState([]);
    const [articleCategories, setArticleCategories] = useState([]);

    //make sure the caching data is working every day update
    const date = new Date();
    const today = date.getDate();
    const lastUpdate = localStorage.getItem('home_last_update');
    if (lastUpdate) {
        if (today !== parseInt(lastUpdate)) {
            localStorage.removeItem('home_slides');
            localStorage.removeItem('home_testimonials');
            localStorage.removeItem('home_articles');
            localStorage.removeItem('services');
            localStorage.removeItem('serviceOptions');
            localStorage.removeItem('general_data');
            localStorage.removeItem('article_featured');
            localStorage.removeItem('article_categories');
            localStorage.setItem('home_last_update', today);
        }
    }

    const slidersCached = useMemo(() => {
        const cached = localStorage.getItem('home_slides');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const articlesCached = useMemo(() => {
        const cached = localStorage.getItem('home_articles');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const testimonialsCached = useMemo(() => {
        const cached = localStorage.getItem('home_testimonials');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const servicesCached = useMemo(() => {
        const cached = localStorage.getItem('services');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const generalCached = useMemo(() => {
        const cached = localStorage.getItem('general_data');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const articleFeaturedCached = useMemo(() => {
        const cached = localStorage.getItem('article_featured');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);
    const articleCategoriesCached = useMemo(() => {
        const cached = localStorage.getItem('article_categories');
        if (cached && cached.length > 0 ) {
            return JSON.parse(cached);
        }
        return [];
    }, []);


    useEffect(() => {
        //check if there is cached data
        if (slidersCached.length > 0) {
            setSlides(slidersCached);
        } else {
            get("slides").then((data) => {
                //cache the data
                localStorage.setItem('home_slides', JSON.stringify(data.data));
                setSlides(data.data);
            });
        }

        if (testimonialsCached.length > 0) {
            setTestimonials(testimonialsCached);
        } else {
            get("testimonials").then((data) => {
                //cache the data
                localStorage.setItem('home_testimonials', JSON.stringify(data.data));
                setTestimonials(data.data);
            });
        }

        if (articlesCached.length > 0) {
            setArticles(articlesCached);
        } else {
            get("articles/featured").then((data) => {
                //cache the data
                localStorage.setItem('home_articles', JSON.stringify(data.data));
                setArticles(data.data);
            });
        }
        if (articleFeaturedCached.length > 0) {
            setArticleFeatured(articleFeaturedCached);
        } else {
            get("articles?is_featured=true").then((data) => {
                localStorage.setItem('article_featured', JSON.stringify(data.data));
                setArticleFeatured(data.data);
            });
        }
        if (articleCategoriesCached.length > 0) {
            setArticleCategories(articleCategoriesCached);
        } else {
            get("articles/categories").then((data) => {
                localStorage.setItem('article_categories', JSON.stringify(data.data));
                setArticleCategories(data.data);
            });
        }
        if (servicesCached.length > 0) {
            setServices(servicesCached);
            let cachedOptions = [];
            servicesCached.map((service) => {
                cachedOptions = [
                    ...cachedOptions,
                    {
                        value: service.id,
                        label: currentLanguage == 'ar' ? service.name.ar : service.name.en
                    }];
            });
            setServiceOption(cachedOptions);
        } else {
            get("services").then((data) => {
                localStorage.setItem('services', JSON.stringify(data.data));
                let allServices = data.data;
                setServices(allServices);
                let options = [];
                allServices.map((service) => {
                    options = [
                        ...options,
                        {
                            value: service.id,
                            label: currentLanguage == 'ar' ? service.name.ar : service.name.en
                        }
                    ];
                });
                localStorage.setItem('options', JSON.stringify(options));
                setServiceOption(options);
            });
        }
        if (generalCached.length > 0) {
            setGeneralData(generalCached);
        } else {
            get("general-data").then((data) => {
                localStorage.setItem('general_data', JSON.stringify(data.data));
                setGeneralData(data.data);
            });
        }

    }, []);
    useEffect(() => {
        if (slides.length > 0 && testimonials.length > 0 && articles.length > 0 && services.length > 0 && typeof generalData == 'object') {
            setHomeIsLoading(false);
        } else {
            console.log('home still loading...');
        }
    }, [generalData]);

    return {
        slides,
        testimonials,
        articles,
        homeIsLoading,
        services,
        serviceOptions,
        generalData,
        articleFeatured,
        articleCategories
    };
};

export default useFetchAllData;