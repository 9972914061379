import React from 'react';
import { Fade } from 'react-awesome-reveal';
import CountUp from 'react-countup';
import {useTranslation, withTranslation} from "react-i18next";

const Facts = (props) => {
  const { t } = useTranslation();
  return (
    <section className="funfact-section pdt-50 pdb-25 pdt-sm-80 pdb-sm-65">
      <Fade cascade direction='left' delay="2" className="funfact-section-obj1">
        <img src="/assets/img/objects/funfact-rtl-obj1.png" alt={props.data.kitchener.seo.ttle} />
      </Fade>
      <Fade cascade direction='left' delay="2" className="funfact-section-obj2">
        <img src="/assets/img/objects/funfact-rtl-obj2.png" alt={props.data.kitchener.seo.ttle} />
      </Fade>
      <Fade  className="funfact-section-obj3">
        <img src="/assets/img/objects/funfact-obj3.png" alt={props.data.kitchener.seo.ttle} />
      </Fade>
      <div className="section-content">
        <div className="container">
          <div className="row">
            <Fade className="col-md-6 col-lg-6 col-xl-3 fadeInUp" cascade direction='up' delay="0" duration="800">
              <div className="funfact">
                <div className="funfact-icon">
                  <span className="webexflaticon base-icon-162-briefcase-2"></span>
                </div>
                <h2 className="count-box">
                  <CountUp end={props.data.home.success_projects} enableScrollSpy duration={2.75}>
                    {({ countUpRef }) => (
                      <span ref={countUpRef} className="count-text" />
                    )}
                  </CountUp>
                </h2>
                <h5 className="title">
                    {t('home.facts.projects')}
                </h5>
              </div>
            </Fade>
            <Fade className="col-md-6 col-lg-6 col-xl-3 fadeInUp" cascade direction='up' delay="0" duration="800">
              <div className="funfact">
                <div className="funfact-icon">
                  <span className="webexflaticon base-icon-101-like-1"></span>
                </div>
                <h2 className="count-box">
                  <CountUp end={props.data.home.happy_clients} enableScrollSpy duration={2.75}>
                    {({ countUpRef }) => (
                      <span ref={countUpRef} className="count-text" />
                    )}
                  </CountUp>
                </h2>
                <h5 className="title">
                    {t('home.facts.clients')}
                </h5>
              </div>
            </Fade>
            <Fade className="col-md-6 col-lg-6 col-xl-3 fadeInUp" cascade direction='up' delay="0" duration="800">
              <div className="funfact">
                <div className="funfact-icon">
                  <span className="webexflaticon webextheme-icon-architect-4"></span>
                </div>
                <h2 className="count-box">
                  <CountUp end={props.data.home.specialists} enableScrollSpy duration={2.75}>
                    {({ countUpRef }) => (
                      <span ref={countUpRef} className="count-text" />
                    )}
                  </CountUp>
                </h2>
                <h5 className="title">
                    {t('home.facts.specialists')}
                </h5>
              </div>
            </Fade>
            <Fade className="col-md-6 col-lg-6 col-xl-3 fadeInUp" cascade direction='up' delay="0" duration="800">
              <div className="funfact">
                <div className="funfact-icon">
                  <span className="webexflaticon base-icon-037-creativity"></span>
                </div>
                <h2 className="count-box">
                  <CountUp end={props.data.home.years_of_experience} enableScrollSpy duration={2.75}>
                    {({ countUpRef }) => (
                      <span ref={countUpRef} className="count-text" />
                    )}
                  </CountUp>
                </h2>
                <h5 className="title">
                    {t('home.facts.experience')}
                </h5>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Facts);
