import {Image} from "antd";
import {GetCurrentLanguage} from "../helper/Helpers";
import {Link} from "react-router-dom";
import React, {Component} from "react";

export class TestimonialSlide extends Component {
    render() {
        return (
            <div className="slide-item">
                <div className="testimonial-thumb">
                    <Image
                        preview={false}
                        src="/assets/img/person.png"
                        alt="client"
                    />
                </div>
                <div className="testimonial-content">
                    <p className="comments">
                        {this.props.slide.content}
                    </p>
                    <h4 className="client-name">{this.props.slide.author_name}</h4>
                    <h6 className="client-designation">{this.props.slide.author_job}</h6>
                </div>
            </div>
        );
    }
}

export default TestimonialSlide;