import React, {useContext} from "react";
import Footer from "../layout/Footer";
import PageTitle from "./PageTitle";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";
import {Image} from "antd";

const Services = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(LanguageContext);
  return (
    <>
      <PageTitle />
      <section className="service-inner-page-section service-section pdt-110 pdb-80">
        <div className="section-content">
          <div className="container">
            <div className="row">
              {
                props.services.map((service, index) => {
                  return (
                      <div className="col-md-6 col-lg-6 col-xl-6">
                        <div className="service-style2">
                            <Link to={`/services/${service.slug}`}>
                                <div className="service-item-thumb">
                            <Image
                                preview={false}
                                className="img-full"
                                src={service.image.src}
                                alt={service.image.alt}
                            />
                            <div className={`service-item-icon ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                              <Image
                                  preview={false}
                                    src={service.icon.src}
                                    alt={service.icon.alt}
                                />
                            </div>
                            <div className="service-item-content">
                              <h6 className="service-categories">
                                {t('services.category')}
                              </h6>
                              <h4 className="service-title">
                                  {currentLanguage == 'ar ' ? service.name.ar : service.name.en}
                              </h4>
                              <div className={`service-item-inner-icon ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                                <i className="webextheme-icon-kitchen"></i>
                              </div>
                            </div>
                          </div>
                            </Link>
                        </div>
                      </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </section>
      <Footer
          data={props.data}
          urls={props.services}
      />
    </>
  );
};

export default Services;
