import React from 'react';
import HomeCarousel from '../layout/HomeCarousel';
import RequestCallBack from './RequestCallBack';
import OurServices from './OurServices';
import CompareImage from './CompareImage';
import WorkFlow from './WorkFlow';
import Facts from './Facts';
import Testimonials from './Testimonials';
import BlogSection from './BlogSection';
import Footer from '../layout/Footer';
import {useTranslation, withTranslation} from "react-i18next";

const  Home = (props) =>  {
    const { t} = useTranslation();
    let fetchData = props.data;
    return (
        <>
            <HomeCarousel
                slides={fetchData.slides} isLoading={fetchData.homeIsLoading}
            />
            <RequestCallBack
                options={fetchData.serviceOptions}
            />
            <OurServices
                services={fetchData.services}
            />
            <CompareImage
                images={fetchData.generalData.home.comparedImages}
            />
            <WorkFlow />
            <Facts data={fetchData.generalData} />
            <Testimonials
                testimonials={fetchData.testimonials}
            />
            <BlogSection
                articles={fetchData.articles}
                data={fetchData.generalData}
            />
            <Footer
                data={fetchData.generalData}
                urls={fetchData.services}
            />
        </>
  );
}

export default withTranslation()(Home);
