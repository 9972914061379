import React, {useContext, useEffect, useState} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {Button, Divider, Dropdown, Image, Layout, Menu, Space} from "antd";
import {
    CloseOutlined,
    DownOutlined,
    FacebookOutlined,
    GlobalOutlined,
    HeatMapOutlined,
    InstagramOutlined,
    MailOutlined,
    MenuFoldOutlined,
    PhoneOutlined,
    ExportOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";

const { Header } = Layout;

const Navbar = (props) => {
    let location = useLocation();
    const [collapsed, setCollapsed] = useState(true);
    const { t} = useTranslation();
    const { changeLanguage } = useContext(LanguageContext);

    const { currentLanguage } = useContext(LanguageContext);

    let kitchenerData = props.data.kitchener;
    let socialLinks = props.data.socialLinks;
    let services = props.services;
    const [servicesMenuItems, setServicesMenuItems] = useState([]);

    useEffect(() => {
        if (services.length > 0) {
            let servicesMenu = services.map((service) => {
                return {
                    key: currentLanguage === "ar" ? service.name.ar : service.name.en,
                    label: <NavLink to={`/services/${service.slug}`}>{service.name[currentLanguage]}</NavLink>,
                };
            });
            setServicesMenuItems(servicesMenu);
        }
    }, [services, currentLanguage]);
    useEffect(() => {
        localStorage.setItem("lastPath", location.pathname);
    }, [location.pathname]);

    const itemsMenu = [
        {
            key: "/",
            label: <NavLink to="/">{ t('menu.home') }</NavLink>,
        },
        {
            key: "/about",
            label: <NavLink to="/about">{ t('menu.about') }</NavLink>,
        },
        {
            key: "/contact-us",
            label: <NavLink to="/contact-us">{ t('menu.contact') }</NavLink>,
        },
        {
            key: "/services",
            label: <NavLink to="/services">{ t('menu.services') }</NavLink>,
            children: servicesMenuItems
        },
        {
            key: "/news",
            label: <NavLink to="/blog">{ t('menu.blog') }</NavLink>,
        },
        
    ];

    const items = [
        {
            label:
                currentLanguage === "en" ? (
                    <Link onClick={() => changeLanguage("ar")}>العربية</Link>
                ) : (
                    <Link onClick={() => changeLanguage("en")}>English</Link>
                ),
            key: currentLanguage === "ar" ? "en" : "ar",
        },
    ];

    useEffect(() => {
        const handleScroll = () => {
            const headerScrollPos = 130;
            const strick = document.getElementById("sticky-header--cloned");
            if (window.scrollY > headerScrollPos) {
                strick.classList.add("sticky-fixed");
            } else if (window.scrollY <= headerScrollPos) {
                strick.classList.remove("sticky-fixed");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function openCollapse() {
        let collapse = document.getElementById("mobile-nav");
        setCollapsed(true);
        collapse.classList.add("expanded");
    }

    function closeCollapse() {
        let collapse = document.getElementById("mobile-nav");
        setCollapsed(false);
        collapse.classList.remove("expanded");
    }

    return (
        <Header className="header">
            <nav className="main-menu sticky-header" id="sticky-header">
                <div className="main-menu-wrapper">
                    <div className="main-menu-logo">
                        <Link to="/">
                            <Image
                                src={kitchenerData.logo}
                                preview={false}
                                alt={currentLanguage == 'ar' ? kitchenerData.description.ar : kitchenerData.description.en}
                            />
                        </Link>
                    </div>
                    <Menu
                        className={`main-nav-menu ${currentLanguage == 'en' ? "ltr" : "rtl"}` }
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={location.pathname}
                        items={itemsMenu}

                    />
                    <div className={ currentLanguage == 'ar' ? 'main-menu-right' : 'main-menu-left' }>
                        <Button
                            type="text"
                            className="mobile-nav-toggler"
                            icon={<MenuFoldOutlined />}
                            onClick={openCollapse}
                            style={{
                                fontSize: "16px",
                                width: 64,
                                height: 64,
                            }}
                        />

                        <ul className="topbar-social">
                            {socialLinks.facebook && <>
                                        <li>
                                            <Link to={socialLinks.facebook}>
                                                <FacebookOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.instagram && <>
                                        <li>
                                            <Link to={socialLinks.instagram}>
                                                <InstagramOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.tiktok && <>
                                        <li>
                                            <Link to={socialLinks.tiktok}>
                                                <ExportOutlined/>
                                            </Link>
                                        </li>

                                    </>}
                        </ul>

                        <div className="language">
                            <Dropdown
                                destroyPopupOnHide
                                autoAdjustOverflow
                                menu={{ items }}
                                trigger={["click"]}
                                dropDownOffset={{ x: 0, y: 0 }}
                                dropdownAlign={{ offset: [-40, 4] }}
                                overlayClassName={`language-dropdown ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}
                            >
                                <Link
                                    onClick={(e) => e.preventDefault()}
                                    className="language-btn"
                                 to="#">
                                    <Space>
                                        <GlobalOutlined />
                                        {currentLanguage == 'ar' ?  'العربية' : 'English'}
                                        <DownOutlined />
                                    </Space>
                                </Link>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>
            <nav
                className="main-menu sticky-header--cloned"
                id="sticky-header--cloned"
            >
                <div className="main-menu-wrapper">
                    <div className="main-menu-logo">
                        <Link to="/">
                            <Image
                                src={kitchenerData.logo}
                                preview={false}
                                alt={currentLanguage == 'ar' ? kitchenerData.description.ar : kitchenerData.description.en}
                            />
                        </Link>
                    </div>
                    <Menu
                        className={`main-nav-menu ${currentLanguage == 'en' ? "ltr" : "rtl"}` }
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={location.pathname}
                        items={itemsMenu}
                    />
                    <div
                        className={ currentLanguage == 'ar' ? 'main-menu-right' : 'main-menu-left' }
                    >
                        <Button
                            type="text"
                            className="mobile-nav-toggler"
                            icon={<MenuFoldOutlined />}
                            onClick={openCollapse}
                            style={{
                                fontSize: "16px",
                                width: 64,
                                height: 64,
                            }}
                        />
                        <ul className="topbar-social">
                            {socialLinks.facebook && <>
                                        <li>
                                            <Link to={socialLinks.facebook}>
                                                <FacebookOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.instagram && <>
                                        <li>
                                            <Link to={socialLinks.instagram}>
                                                <InstagramOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.tiktok && <>
                                        <li>
                                            <Link to={socialLinks.tiktok}>
                                                <ExportOutlined/>
                                            </Link>
                                        </li>

                                    </>}
                        </ul>

                        <div className="language">
                            <Dropdown
                                destroyPopupOnHide
                                autoAdjustOverflow
                                menu={{
                                    items,
                                }}
                                trigger={["click"]}
                                overlayClassName={`language-dropdown ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}
                            >
                                <Link
                                    to="#"
                                    onClick={(e) => e.preventDefault()}
                                    className="language-btn"
                                >
                                    <Space>
                                        <GlobalOutlined />
                                        {currentLanguage == 'ar' ? 'العربية' : 'English'}
                                        <DownOutlined />
                                    </Space>
                                </Link>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </nav>

            <div
                id="mobile-nav"
                className={`mobile-nav-wrapper ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}
            >
                <div className="mobile-nav-overlay mobile-nav-toggler"></div>
                <div className={`mobile-nav-content ${currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                    <Link
                        to="#"
                        onClick={closeCollapse}
                        className={`mobile-nav-close mobile-nav-toggler ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}
                    >
                        <CloseOutlined/>
                    </Link>
                    <div className="logo-box">
                        <Link to="/">
                            <Image src={kitchenerData.logo} preview={false}/>
                        </Link>
                    </div>
                    <Divider/>
                    <div className="mobile-nav-container"></div>

                    <Menu
                        className="main-nav-menu"
                        theme="light"
                        defaultSelectedKeys={location.pathname}
                        items={itemsMenu}
                    />
                    <div className="language-mobile">
                        <Link
                            className="d-flex"
                            style={{columnGap: "1em"}}
                            to="#" onClick={() => changeLanguage(currentLanguage == "ar" ? "en" : "ar")}
                        >
                            <GlobalOutlined />
                            {currentLanguage == "ar" ? "English" : "العربية"}
                        </Link>
                    </div>
                    <ul className={`list-items mobile-sidebar-contact mt-0 ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}>
                        <li>
                            <HeatMapOutlined className="mrr-10 text-primary-color"/>
                            {currentLanguage == 'ar' ? kitchenerData.address.ar : kitchenerData.address.en}
                        </li>
                        <li>
                            <MailOutlined className="mrr-10 text-primary-color"/>
                            <Link to={`mailto:${kitchenerData.email}`}>{kitchenerData.email}</Link>
                        </li>
                        <li>
                            <PhoneOutlined className="mrr-10 text-primary-color"/>
                            <Link to={`tel:${kitchenerData.phone}`}>{kitchenerData.phone}</Link>
                        </li>
                    </ul>
                    <ul className="social-list list-primary-color">
                        {socialLinks.facebook && <>
                            <li>
                                <Link to={socialLinks.facebook}>
                                    <FacebookOutlined/>
                                </Link>
                            </li>
                        </>
                        }
                        {socialLinks.instagram && <>
                            <li>
                                <Link to={socialLinks.instagram}>
                                    <InstagramOutlined/>
                                </Link>
                            </li>
                        </>
                        }
                        {socialLinks.tiktok && <>
                            <li>
                                <Link to={socialLinks.tiktok}>
                                    <ExportOutlined/>
                                </Link>
                            </li>

                        </>}
                    </ul>
                </div>
            </div>
        </Header>
    );
};

export default Navbar;
