import React, {useEffect, useState} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import Home from "./components/home/Home";
import AboutUs from "./components/about/AboutUs";
import ContactUs from "./components/contact/ContactUs";
import Service from "./components/services/service/Service";
import Services from "./components/services/Services";
import Article from "./components/blog/article/Article";
import Blog from "./components/blog/Blog";
import Preloader from "./components/layout/Preloader";
import NotFound from "./components/layout/NotFound";
import {axiosEventEmitter} from "./components/api/axios";

const AppRoutes = (props) => {
    let [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handle404 = () => {
            navigate('/404');
        };

        // Subscribe to the custom event
        axiosEventEmitter.on('axios:404', handle404);

        return () => {
            // Unsubscribe from the event when the component unmounts
            axiosEventEmitter.off('axios:404', handle404);
        };
    }, [navigate]);
    useEffect(() => {
        setLoading(props.data.homeIsLoading);
    }, [props.data]);


    return (
        loading ? <Preloader /> :
        <div>
            <Routes>
                <Route
                    path=""
                    element={
                        <Home
                            data={props.data}
                        />
                    }
                />
                <Route
                    path="/about"
                    element={
                        <AboutUs
                            data={props.data.generalData}
                            services={props.data.services}
                        />
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <ContactUs
                            data={props.data.generalData}
                            services={props.data.services}
                        />
                    }
                />
                <Route path="/services">
                    <Route
                        path=""
                        element={
                            <Services
                                data={props.data.generalData}
                                services={props.data.services}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <Service
                                data={props.data.generalData}
                                services={props.data.services}
                            />
                        }
                    />
                </Route>

                <Route path="/blog">
                    <Route
                        path=""
                        element={
                            <Blog
                                data={props.data.generalData}
                                services={props.data.services}
                                articles={props.data.articles}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <Article
                                data={props.data.generalData}
                                services={props.data.services}
                            />
                        }
                    />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;
