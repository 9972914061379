import React, {useContext} from "react";
import Footer from "../layout/Footer";
import PageTitle from "./PageTitle";
import { Fade } from "react-awesome-reveal";
import LanguageContext from "../../LanguageContext";
import {useTranslation} from "react-i18next";

const AboutUs = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(LanguageContext);
  return (
    <>
      <PageTitle />
      <section
        className="about-section pdt-110 pdb-105 bg-no-repeat bg-cover bg-pos-cb"
        data-background="/assets/img/bg/abs-bg3.png"
        data-overlay-light="4"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-6 p-0" style={{
              position: "relative",
              zIndex: 1111,
             }}>
              <Fade direction={currentLanguage == 'ar' ? 'left' : 'right'} duration="1500" triggerOnce={true}
              delay={0}
              >
              <div className="about-image-box-style1 about-side-line mrr-60 mrr-lg-0">
                <figure className={`about-image1 js-tilt d-none d-md-block d-lg-block d-xl-block ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                  <img
                    className="img-full"
                    src="/assets/img/about/about-sm1.jpg"
                    alt=""
                  />
                </figure>
                <figure className={`about-image2 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                  <img
                    className="img-full"
                    src="/assets/img/about/about-lg1.jpg"
                    alt=""
                  />
                </figure>
              </div>
              </Fade>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6" style={{
              position: "relative",
              zIndex: 1000,
             }}>
              <Fade
                direction={currentLanguage == 'ar' ? 'right' : 'left'}
                delay="0"
                duration="1500" >
                <h5 className={`side-line-left subtitle text-primary-color ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                  {currentLanguage == 'en' ? props.data.about.title.en : props.data.about.title.ar}
                </h5>
                <h2 className="mrb-45 mrb-lg-35">
                  { t('about.title.first') } {" "}
                  <span className="text-primary-color">{t('about.title.architecture')}</span>
                  {t('about.title.last')}
                </h2>
                <p className="about-text-block mrb-40">
                  {currentLanguage === "en" ? props.data.about.content.en : props.data.about.content.ar}
                </p>
                <div className="row mrb-30 mrb-lg-40">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <ul className="order-list primary-color">
                      <li>business applications through</li>
                      <li>procedures whereas processes</li>
                    </ul>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <ul className="order-list primary-color">
                      <li>revolutionary catalysts for chang</li>
                      <li>catalysts for chang the Seamlessly</li>
                    </ul>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6">
                    <div className="featured-icon-box mrb-15">
                      <div className="featured-icon">
                        <i className="webexflaticon webextheme-icon-003-staircase"></i>
                      </div>
                      <div className="featured-content">
                        <h4 className="featured-title">Exclusive Design</h4>
                        <p className="featured-desc">
                          Quickly productivate just in time strategic theme lacus
                          mirina magna
                        </p>
                      </div>
                    </div>
                    <div className="featured-icon-box mrb-sm-40">
                      <div className="featured-icon">
                        <i className="webexflaticon base-icon-158-employee-2"></i>
                      </div>
                      <div className="featured-content">
                        <h4 className="featured-title">Professional Team</h4>
                        <p className="featured-desc mrb-0">
                          Quickly productivate just in time strategic theme lacus
                          mirina magna
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
                    <div className="experience">
                      <p className="experience-text">
                        { currentLanguage == 'en' ? props.data.about.years_of_experience_title.en : props.data.about.years_of_experience_title.ar }
                      </p>
                      <h4 className="experience-year">{ props.data.about.years_of_experience_title.years_of_experience }+</h4>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <Footer
          data={props.data}
          urls={props.services}
      />
    </>
  );
};

export default AboutUs;
