import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Footer from "../../layout/Footer";
import PageTitle from "./PageTitle";
import {DownloadOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import LanguageContext from "../../../LanguageContext";
import {useContext} from "react";
import {get} from "../../api/axios";
import {Image} from "antd";
import Preloader from "../../layout/Preloader";
import {useTranslation} from "react-i18next";
const Service = (props) => {
  const { id } = useParams();
  const { currentLanguage } = useContext(LanguageContext);
    const { t } = useTranslation();
  const [service, setservice] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    get(`services/${id}`)
        .then(response => {
          setservice(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching post:', error);
          setLoading(true);
        });
  }, [id]);
  const onChange = (key) => {
    console.log(key);
  };

  return (
      loading ? <Preloader /> :
            <>
              <PageTitle title={currentLanguage == "ar"
                  ? service.name.ar
                  : service.name.en} />
              <section className="service-details-page pdt-110 pdb-110 pdb-lg-75">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-7">
                      <div className="service-detail-text">
                        <div className="blog-standared-img slider-blog mrb-40">
                          <Image
                              preview={false}
                              className="img-full"
                              src={service.image.src}
                              alt={service.image.alt}
                          />
                        </div>
                        <h3 className="mrb-15">
                          {currentLanguage == "ar"
                              ? service.name.ar
                              : service.name.en}
                        </h3>
                        <p className="about-text-block mrb-40">
                          {currentLanguage == "ar"
                              ? service.description.ar
                              : service.description.en}
                        </p>
                        <div className="service-details-content">
                          <div className="row d-flex">
                            <div className="col-lg-12 col-xl-6 pdl-40 pdl-lg-15">
                              <h3 className="mrb-20">Service Included</h3>
                              <ul className="order-list primary-color mrb-lg-40">
                                {
                                  service.features.map((feature, index) => {
                                    return (
                                        <li key={index}>
                                          {feature}
                                        </li>
                                    );
                                  })
                                }
                              </ul>
                            </div>
                            <div className="col-lg-12 col-xl-6">
                              <Image
                                    preview={false}
                                  className="img-full mrb-lg-35"
                                  src={service.feature_image.src}
                                  alt={service.feature_image.alt}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 sidebar-right">
                      <div className="service-nav-menu mrb-30">
                        <div className="service-link-list">
                          <ul>
                            {
                              props.services.map((service, index) => {
                                return (
                                    <li key={index} className={id == service.slug ? 'active' : ''}>
                                      <Link to={`/services/${service.slug}`}>
                                        {currentLanguage === "en" ? (
                                            <RightOutlined/>
                                        ) : (
                                            <LeftOutlined/>
                                        )}
                                        {
                                          currentLanguage === "ar"
                                              ? service.name.ar
                                              : service.name.en
                                        }
                                      </Link>
                                    </li>
                                );
                              })
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar-widget-need-help">
                        <div className="need-help-icon">
                          <span className="webexflaticon base-icon-phone-call"></span>
                        </div>
                        <h4 className="need-help-title">
                          {t('services.offer')}
                        </h4>
                        <div className="need-help-contact">
                          <p className="mrb-5">{t('services.contact')}</p>
                          <Link to={`tel:${props.data.kitchener.phone}`} dir="ltr">
                            {
                              props.data.kitchener.phone
                            }
                          </Link>
                        </div>
                      </div>
                      {
                          service.brochure.src && <div className="sidebar-widget">
                            <div className="brochure-download">
                              <h4 className="mrb-40 widget-title">
                                {t('services.brochure')}
                              </h4>
                              <p>{t('services.instruction')}</p>
                              <Link to={service.brochure.src} className="cs-btn-one">
                                <DownloadOutlined className={currentLanguage == 'ar' ? 'mrr-10' : 'mrl-10'} />
                                <span className="far fa-file-pdf"></span>
                                {t('services.download')}
                              </Link>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </section>
              <Footer
                  data={props.data}
                  urls={props.services}
              />
            </>
  );
};

export default Service;
