import React from 'react';

const PrevArrow = ({ onClick }) => (
      <button
          type="button"
          data-role="none"
          onClick={onClick}
          className="slick-arrow slick-custom-next"
      >
        <i className="base-icon-right-chevron"></i>
      </button>
);

const NextArrow = ({onClick}) => (
      <button
          type="button"
          data-role="none"
          onClick={onClick}
          className="slick-arrow slick-custom-prev"
      >
        <i className="base-icon-left-chevron"></i>
      </button>
);

export { PrevArrow, NextArrow };