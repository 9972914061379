// src/components/NotFound.js
import React from 'react';
import {Result, Button, Image} from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary"><Link to="/">Back Home</Link></Button>}
    />
);

export default NotFound;
