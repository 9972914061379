import React, {useContext} from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import {Image} from "antd";
import LanguageContext from "../../LanguageContext";
import {useTranslation} from "react-i18next";

const OurServices = (props) => {
    const { t } = useTranslation();
    const { currentLanguage } = useContext(LanguageContext);

    return (
    <section className="service-section bg-no-repeat bg-cover bg-pos-cb pdt-105 pdb-105">
      <Fade
        className="section-title-services text-center fadeInUp"
        cascade
        direction="up"
        delay={0}
        duration={1500}
      >
        <div className="row justify-content-start w-100">
          <div className="col-xl-8">
            <div className="title-box-center">
              <h5 className="sub-title-line-bottom text-primary-color mrb-10">
                {t('home.our_services_question')}
              </h5>
              <h2 className="title">
                {t('home.our_services_title_1')}
                <span className="text-primary-color">
                  {t('home.our_services_title_2')}
                </span>
                {t('home.our_services_title_3')}
              </h2>
            </div>
          </div>
        </div>
      </Fade>
      <div className="section-content">
        <div className="container-fluid w-90">
          <div className="row">
            {
                props.services.map((service, index) => {
                  return (
                      <div key={index} className="col-xl-3 col-lg-6 col-md-6">
                        <div className="service-style2">
                          <Link to={`/services/${service.slug}`}>
                            <div className="service-item-thumb">
                                <Image
                                    preview={false}
                                    className="img-full"
                                    src={service.image.src}
                                    alt={service.image.alt}
                                />
                              <div className={`service-item-icon ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}>
                                <Image preview={false}
                                    // className="img-full"
                                       src={service.icon.src}
                                       alt={service.icon.alt}
                                />
                                {/*<i className="webextheme-icon-kitchen"></i>*/}
                              </div>
                              <div className="service-item-content">
                              <h6 className="service-categories">
                                        {t('services.category')}
                                    </h6>
                                    <h4 className="service-title">
                                            {currentLanguage == 'ar ' ? service.name.ar : service.name.en}
                                    </h4>
                                <div className={`service-item-inner-icon ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}>
                                  <i className="webextheme-icon-kitchen"></i>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                  );
                })
            }

              {/*<div className="col-xl-3 col-lg-6 col-md-6">*/}
            {/*  <div className="service-style2">*/}
            {/*    <div className="service-item-thumb">*/}
            {/*      <img*/}
            {/*          className="img-full"*/}
            {/*          src="/assets/img/service/service_03.jpg"*/}
            {/*          alt=""*/}
            {/*      />*/}
            {/*      <div className="service-item-icon">*/}
            {/*        <i className="webextheme-icon-measure"></i>*/}
            {/*      </div>*/}
            {/*      <div className="service-item-content">*/}
            {/*        <h6 className="service-categories">تصميم وتنفيذ</h6>*/}
            {/*        <h4 className="service-title">*/}
            {/*          <Link to="/services/kitchens">المطابخ</Link>*/}
            {/*        </h4>*/}
            {/*        <div className="service-item-inner-icon">*/}
            {/*          <i className="webextheme-icon-measure"></i>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="col-xl-3 col-lg-6 col-md-6">*/}
            {/*  <div className="service-style2">*/}
            {/*    <div className="service-item-thumb">*/}
            {/*      <img*/}
            {/*        className="img-full"*/}
            {/*        src="/assets/img/service/service_01.jpg"*/}
            {/*        alt=""*/}
            {/*      />*/}
            {/*      <div className="service-item-icon">*/}
            {/*        <i className="webextheme-icon-stairs"></i>*/}
            {/*      </div>*/}
            {/*      <div className="service-item-content">*/}
            {/*        <h6 className="service-categories">تصميم وتنفيذ</h6>*/}
            {/*        <h4 className="service-title">*/}
            {/*          <Link to="/">غرف الملابس</Link>*/}
            {/*        </h4>*/}
            {/*        <div className="service-item-inner-icon">*/}
            {/*          <i className="webextheme-icon-stairs"></i>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="col-xl-3 col-lg-6 col-md-6">*/}
            {/*  <div className="service-style2">*/}
            {/*    <div className="service-item-thumb">*/}
            {/*      <img*/}
            {/*        className="img-full"*/}
            {/*        src="/assets/img/service/service_02.jpg"*/}
            {/*        alt=""*/}
            {/*      />*/}
            {/*      <div className="service-item-icon">*/}
            {/*        <i className="webextheme-icon-kitchen"></i>*/}
            {/*      </div>*/}
            {/*      <div className="service-item-content">*/}
            {/*        <h6 className="service-categories">تصميم وتنفيذ</h6>*/}
            {/*        <h4 className="service-title">*/}
            {/*          <Link to="/">وحدات التلفاز</Link>*/}
            {/*        </h4>*/}
            {/*        <div className="service-item-inner-icon">*/}
            {/*          <i className="webextheme-icon-kitchen"></i>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="col-xl-3 col-lg-6 col-md-6">*/}
            {/*  <div className="service-style2">*/}
            {/*    <div className="service-item-thumb">*/}
            {/*      <img*/}
            {/*        className="img-full"*/}
            {/*        src="/assets/img/service/service_05.jpg"*/}
            {/*        alt=""*/}
            {/*      />*/}
            {/*      <div className="service-item-icon">*/}
            {/*        <i className="webextheme-icon-kitchen"></i>*/}
            {/*      </div>*/}
            {/*      <div className="service-item-content">*/}
            {/*        <h6 className="service-categories">تصميم وتنفيذ</h6>*/}
            {/*        <h4 className="service-title">*/}
            {/*          <Link to="/">وحدات الحمام</Link>*/}
            {/*        </h4>*/}
            {/*        <div className="service-item-inner-icon">*/}
            {/*          <i className="webextheme-icon-kitchen"></i>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
