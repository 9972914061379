import axios from "axios";
import EventEmitter from 'events';

export const token = localStorage.getItem("token");

// Create Axios instance
const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Set base URL
  headers: {
    'Accept': "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
    'device': "web",
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
export const axiosEventEmitter = new EventEmitter();


// Set authorization header with token from local storage
// axiosApi.defaults.headers["Authorization"] = `Bearer ${token}`;

// Intercept responses to handle errors
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 404) {
      console.log('404 error');
      axiosEventEmitter.emit('axios:404');
    }
    console.log('error', error.response);
    return Promise.reject(error);
  }
);

// Exported functions for making HTTP requests

export async function get(url) {
  return await axiosApi
    .get(url)
    .then((response) => response.data)
    .catch((err) => err);
}

export async function post(url, data) {
  return axiosApi.post(url, data).then((res) => res.data);
}

export async function postFromData(url, data) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.post(url, formData).then((res) => res.data);
}

export async function put(url, data) {
  return axiosApi.put(url, data).then((response) => response.data);
}

export async function putFromData(url, data) {
  const formData = new FormData();
  const dataList = data;
  for (let item in dataList) {
    formData.append(item, dataList[item]);
  }
  return axiosApi.put(url, formData).then((res) => res.data);
}

export async function del(url) {
  return await axiosApi.delete(url).then((response) => {
    return response.data;
  });
}

export default axiosApi;
