import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'
import React, { Component } from 'react'

const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 150,
      }}
      spin
      color='#0072B4'
    />
  );
export class Preloader extends Component {
    render() {
        return (
            <div id="preloader">
                <div id="ctn-preloader" className="ctn-preloader">
                    <div className="animation-preloader">
                        <Spin indicator={antIcon}
                            className='spinner'
                            size="large" />
                        <div className="txt-loading">
                            <span data-text-preloader="K" className="letters-loading">K</span>
                            <span data-text-preloader="I" className="letters-loading">I</span>
                            <span data-text-preloader="T" className="letters-loading">T</span>
                            <span data-text-preloader="C" className="letters-loading">C</span>
                            <span data-text-preloader="H" className="letters-loading">H</span>
                            <span data-text-preloader="E" className="letters-loading">E</span>
                            <span data-text-preloader="N" className="letters-loading">N</span>
                            <span data-text-preloader="E" className="letters-loading">E</span>
                            <span data-text-preloader="R" className="letters-loading">R</span>
                        </div>
                    </div>
                    <div className="loader-section section-left"></div>
                    <div className="loader-section section-right"></div>
                </div>
            </div>
        )
    }
}

export default Preloader