import React, {useEffect, useState} from 'react';
import i18n from './i18n'; // Import your i18n configuration
import LanguageContext from './LanguageContext';

const LanguageProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            setCurrentLanguage(lng);
            // document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr'; // Set document direction
        };

        i18n.on('languageChanged', handleLanguageChange);
        handleLanguageChange(i18n.language); // Initialize direction

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
