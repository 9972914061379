import React, {Component} from "react";
import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../layout/CarouselArrows";
import { GetCurrentLanguage } from "../helper/Helpers";
import CarouselSLide from "../layout/CarouselSLide";
import {TestimonialSlide} from "../layout/TestimonialSLide";
import LanguageContext from "../../LanguageContext";
import {useTranslation, withTranslation} from "react-i18next";

export class  Testimonials extends Component {
  static contextType = LanguageContext;
  render() {
    const { currentLanguage } = this.context;
    const { t } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      autoplay: false,
      initialSlide: 0,
      slidesToScroll: 2,
      rtl: currentLanguage === 'ar',
      prevArrow: <PrevArrow />, // Use your custom LeftArrow component
      nextArrow: <NextArrow />,
      adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <section className={`testimonial-section bg-silver pdt-105 pdb-110 pdb-lg-70 bg-no-repeat bg-cover bg-pos-ct ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
          <div className={`testimonial-section-obj1 ${currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}>
            <img src="/assets/img/objects/testimonial-obj1.png" alt="" />
          </div>
          <Fade className="section-title mrb-55 fadeInUp" delay="0" duration="1500">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-xl-4 col-lg-4 col-md-12"></div>
                <div className="col-xl-8 col-lg-8 col-md-12">
                  <div className="title-box-center">
                    <h5 className={`side-line-left text-primary-color mrb-10 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                        {t('home.testimonials.title')}
                    </h5>
                    <h2 className="mrb-35">
                        {t('home.testimonials.subtitle.what')}

                       <span className="text-primary-color">
                        {t('home.testimonials.subtitle.our_clients')}
                       </span>{" "}
                        {t('home.testimonials.subtitle.say')}
                    </h2>
                    <p className="mrb-0">
                        {t('home.testimonials.description')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <div className="section-content">
            <div className="container">
              <div className="row">
                <Slider
                    className="testimonial_rtl testimonial-style1 mrb-lg-40"
                    {...settings}
                >
                  { this.props.testimonials.map((slide, index) => {
                    return (
                        <TestimonialSlide key={index} slide={slide} />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default withTranslation()(Testimonials);
