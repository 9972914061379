import React, {useContext} from "react";
import {Fade} from "react-awesome-reveal";
import {Link} from "react-router-dom";
import {Image} from "antd";
import LanguageContext from "../../LanguageContext";
import {useTranslation} from "react-i18next";

const BlogSection = (props) => {
  const { currentLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <section className="blog-section bg-no-repeat bg-cover bg-pos-ct pdt-105">
      <Fade className="section-title mrb-55 fadeInUp" delay="0" duration="1500">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-8 col-md-12">
              <div className="title-box-center">
                <h5 className={`side-line-left text-primary-color mrb-10 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                    {t('home.blog.title')}
                </h5>
                <h2 className="mrb-25">
                  {t('home.blog.subtitle.news')}{" "}
                  <span className="text-primary-color">
                    {t('home.blog.subtitle.articles')}
                  </span>
                </h2>
                <p className="mrb-0 mrb-md-40">
                  {
                    t('home.blog.description')
                  }
                </p>
              </div>
            </div>
            <div className={`col-xl-6 col-lg-4 col-md-12 text-lg-end ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
              <Link to="/blog" className="animate-btn-style2">
                {t('home.blog.read_more')}
              </Link>
            </div>
          </div>
        </div>
      </Fade>
      <div className="section-content mrb-80">
        <div className="container">
          <div className="row justify-content-center">
            {
              props.articles.map((article, index) => {
                return (
                    <div key={index} className="col-md-6 col-lg-6 col-xl-4">
                      <div className="news-wrapper mrb-30">
                        <Link to={`/blog/${article.slug}`}>
                        <div className="news-thumb">
                          <Image
                              preview={false}
                              src={article.image.src ?? "/assets/img/news/01.jpg"}
                              className="img-full"
                              alt={article.image.alt ?? "blog"}
                          />
                          <div className="news-top-meta">
                            <span
                                className="entry-category">{currentLanguage == 'ar' ? article.category.name.ar : article.category.name.en}</span>
                          </div>
                        </div>
                        <div className="news-description">
                          <h4 className="the-title">
                              {currentLanguage == 'ar' ? article.title.ar : article.title.en}
                          </h4>
                          <p className="the-content">
                            {currentLanguage == 'ar' ? article.short_description.ar : article.short_description.en}
                          </p>
                          <div className="news-bottom-part">
                            {/*<div className="post-author">*/}
                            {/*  <div className="author-img">*/}
                            {/*    /!*<Link to={`/${article.slug}`}>*!/*/}
                            {/*    /!*  <Image*!/*/}
                            {/*    /!*      preview={false}*!/*/}
                            {/*    /!*      className={"rounded-circle"}*!/*/}
                            {/*    /!*      src={props.data.kitchener.logo}*!/*/}
                            {/*    /!*  />*!/*/}
                            {/*    /!*</Link>*!/*/}
                            {/*  </div>*/}
                            {/*  <span>*/}
                            {/*    <Link to="/">Kitchener</Link>*/}
                            {/*  </span>*/}
                            {/*</div>*/}
                            <div className="post-link">
                              <span className="entry-date">
                                <i className="far fa-calendar-alt mrr-10 text-primary-color"></i>
                                {article.published_date_formated}
                              </span>
                            </div>
                          </div>
                        </div>
                        </Link>
                      </div>
                    </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action-inner">
            <div className="call-to-action-left">
              <div className="call-to-action-icon">
                <span className="webexflaticon base-icon-chat1"></span>
              </div>
              <div className={`call-to-action-content ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                <p className="call-to-action-sub-title">
                    {t('home.blog.ready')}
                </p>
                <h3 className="call-to-action-title">
                    {t('home.blog.help')}
                </h3>
              </div>
            </div>
            <div className="call-to-action-btn-box mrt-md-30">
              <Link to="/#request-service" className="animate-btn-style4">
                    {t('home.blog.contact')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
