import { Layout } from "antd";
import React, {useEffect, useState} from "react";
import AppRoutes from "../../AppRoutes";
import Navbar from "./Navbar";
import ScrollToTop from "react-scroll-to-top";
import Scroll from "./Scroll";
import {useLocation} from "react-router-dom";
const { Content } = Layout;
const LayoutComponent = (props) => {
    const [layout] = useState(true);
    const location = useLocation();
    const fetchGeneralData = props.data;
    useEffect(() => {
        if (location.hash === '#request-service') {
            const requestServiceDiv = document.getElementById('request-service');
            if (requestServiceDiv) {
                requestServiceDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
    return (
        <>{layout ? (
            <Layout>
                <Navbar
                    data={fetchGeneralData.generalData}
                    services={fetchGeneralData.services}
                />
                <Content
                    style={{
                        margin: "24px 16px 0",
                    }}
                >
                    <AppRoutes data={fetchGeneralData} />
                    <ScrollToTop smooth className="anim-scroll-to-top" component={<Scroll />}/>
                </Content>
            </Layout>
        ) : (
            <AppRoutes data={fetchGeneralData} />
        )}
        </>
    );
};
export default LayoutComponent;
