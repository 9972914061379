import React, {useContext, useEffect, useState} from 'react';
import {Form, Input, notification, Select, Spin} from 'antd';
import { Fade } from "react-awesome-reveal";
import PhoneInput from 'antd-phone-input';
import {post} from "../api/axios";
import useForm from "antd/lib/form/hooks/useForm";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";

const RequestCallBack = (props) => {
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const {currentLanguage} = useContext(LanguageContext);
    const [form] = useForm();
    useEffect(() => {
        const requestServiceDiv = document.getElementById('request-service');
        const handleScroll = () => {
            if (window.pageYOffset >= requestServiceDiv.offsetTop) {
                // Logic to handle reaching the div
                // You can add further actions here
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onFinish = (values) => {
        setLoading(false);
        values.phone = values.phone.countryCode + values.phone.areaCode + values.phone.phoneNumber;
        post("service-request/store", values)
            .then((res) => {
                let dir = currentLanguage == 'ar' ? 'bottomRight' : 'bottomLeft';
                form.resetFields();
                notification.success({message: res.message,  placement: dir});
                setLoading(true);
            });
    };
    const validator = (_, { valid }) => {
        if (valid()) {
            return Promise.resolve();
        }
        let msg = t('validation.invalidField', {field: t('form.phone')});
        return Promise.reject(msg);
    };

    return (
        <section className="feature-section">
            <div className="custom-xs-container">
                <div
                    className={`feature-box-area bg-no-repeat bg-cover bg-pos-cc mrt-sm-110 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr'}`}
                    id="request-service"
                >
                    <Fade
                        className={currentLanguage == 'ar' ? 'fadeInLeft' : 'fadeInRight'}
                        cascade
                        direction={currentLanguage == 'ar' ? 'left' : 'right'}
                        delay={100}
                        duration={200}
                    >
                        <div className="request-a-call-back-form">
                            <h5 className={`side-line-left text-primary-color mrb-10 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                                {t('home.request_service')}
                            </h5>
                            <h2 className="mrt-0 mrb-45 solid-bottom-line">
                                {t('home.get_your_service')}
                            </h2>
                            {
                                loading ? (
                                    <Form
                                        form={form}
                                        onFinish={onFinish}
                                        size='large'
                                        labelCol={{
                                            span: 7,
                                        }}
                                        wrapperCol={{
                                            span: 15,
                                        }}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        autoComplete="off"
                                    >
                                        <div className="row mrb-10">
                                            {/* Item */}
                                            <div className="col-md-12">
                                                <div className='mrb-20'>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('validation.required', {field: t('form.email')})
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={t('form.email')} className="w-100 form-control" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            {/* Item */}
                                            <div className="col-md-12">
                                                <div className='mrb-20'>
                                                    <Form.Item
                                                        name="phone"
                                                        rules={[{ validator }]}
                                                    >
                                                        <PhoneInput enableSearch />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            {/* Item */}
                                            <div className="col-md-12">
                                                <div className='mrb-20'>
                                                    <Form.Item
                                                        name="name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('validation.required', {field: t('form.name')}),
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={t('form.name')} className="w-100 form-control" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            {/* Item */}
                                            <div className="col-lg-12">
                                                <div className='mrb-20'>
                                                    <Form.Item
                                                        name="service_id"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('validation.required', {field: t('form.service')}),
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            allowClear
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "")
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                            placeholder={t('form.select_service')}
                                                            options={props.options}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-grid gap-2 mrb-0">
                                                <button type="submit" className="cs-btn-one btn-square btn-primary-color">
                                                    {t('home.request_service_btn')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                ) : <>
                                    <Spin size="large" />
                                </>
                            }
                        </div>
                    </Fade>
                </div>
            </div >
        </section>
    );
}

export default RequestCallBack;
