import {Image} from "antd";
import {Link} from "react-router-dom";
import {Component} from "react";
import LanguageContext from "../../LanguageContext";

export class CarouselSlide extends Component {
    static contextType = LanguageContext;
    render() {
        const { currentLanguage } = this.context;
        return (
            <div className="slide-item" key={this.props.index}>
                <Image
                    className="image-layer"
                    width="100%"
                    src={this.props.slide.image.src}
                    alt={this.props.slide.image.alt}
                    preview={false}
                />
                <div
                    className="auto-container"
                    style={{direction: currentLanguage === "ar" ? "rtl" : "ltr"}}
                >
                    <div className="row clearfix">
                        <div className="col-xl-8 col-lg-12 col-md-12 content-column">
                            <div className="content-box">
                                <h1 className="home-carousel-title">
                                    {currentLanguage == 'ar' ? this.props.slide.title.ar : this.props.slide.title.en}
                                </h1>
                                <p className="home-carousel-text">
                                    {currentLanguage == 'ar' ? this.props.slide.description.ar : this.props.slide.description.en}
                                </p>
                                <div className="btn-box">
                                    <Link className="animate-btn-style3" to={this.props.slide.btn_link}>
                                        {currentLanguage == 'ar' ? this.props.slide.btn_text.ar : this.props.slide.btn_text.en}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CarouselSlide;
