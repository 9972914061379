import React, {useContext, useState} from "react";
import Footer from "../layout/Footer";
import PageTitle from "./PageTitle";
import {
  ExportOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {Form, Input, notification, Spin} from "antd";
import PhoneInput from "antd-phone-input";
import TextArea from "antd/es/input/TextArea";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";
import useForm from "antd/lib/form/hooks/useForm";
import {post} from "../api/axios";

const ContactUs = (props) => {
  const [loading, setLoading] = useState(true);
    const socialLinks = props.data.socialLinks;
    const {t} = useTranslation();
    const {currentLanguage} = useContext(LanguageContext);
  const [form] = useForm();

  const onFinish = (values) => {
    setLoading(false);
    values.phone = values.phone.countryCode + values.phone.areaCode + values.phone.phoneNumber;
    post("contact", values)
        .then((res) => {
            let dir = currentLanguage == 'ar' ? 'bottomRight' : 'bottomLeft';
            notification.success({message: res.message,  placement: dir});
            setLoading(true);
        });
  };
  const validator = (_, { valid }) => {
    if (valid()) {
      return Promise.resolve();
    }
    let msg = t('validation.invalidField', {field: t('form.phone')});
    return Promise.reject(msg);
  };
  return (
    <>
      <PageTitle />
      <section
        className="contact-section pdt-110 pdb-110"
        data-overlay-light="2"
      >
        <div className="container">
          <div className="row mrb-80">
            <div className="col-md-12 col-lg-12 col-xl-4">
              <h5 className={`side-line-left text-primary-color mrt-0 mrb-5 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                {currentLanguage == 'en' ? props.data.contact.title.en : props.data.contact.title.ar}
              </h5>
              <h2 className="faq-title mrb-30">{currentLanguage == 'en' ? props.data.contact.content.en : props.data.contact.content.ar}</h2>
              <ul className="social-list list-lg list-primary-color list-flat mrb-lg-60 clearfix">
                {socialLinks.facebook && <>
                  <li>
                    <Link to={socialLinks.facebook}>
                      <FacebookOutlined/>
                    </Link>
                  </li>
                </>
                }
                {socialLinks.instagram && <>
                  <li>
                    <Link to={socialLinks.instagram}>
                      <InstagramOutlined/>
                    </Link>
                  </li>
                </>
                }
                {socialLinks.tiktok && <>
                  <li>
                    <Link to={socialLinks.tiktok}>
                      <ExportOutlined/>
                    </Link>
                  </li>

                </>}
              </ul>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-8">
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="contact-block d-flex mrb-30">
                    <div className="contact-icon">
                      <i className="base-icon-map"></i>
                    </div>
                    <div className={`contact-details ${currentLanguage == 'ar' ? 'mrl-30': 'mrr-30'}`}>
                      <h5 className="icon-box-title mrb-10">
                        {t('general.address')}
                      </h5>
                      <p className="mrb-0">
                        {currentLanguage == 'en' ? props.data.kitchener.address.en : props.data.kitchener.address.ar}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="contact-block d-flex mrb-30">
                    <div className="contact-icon">
                      <i className="base-icon-094-email-2"></i>
                    </div>
                    <div className={`contact-details ${currentLanguage == 'ar' ? 'mrl-30': 'mrr-30'}`}>
                      <h5 className="icon-box-title mrb-10">
                        {t('general.emailUs')}
                      </h5>
                      <p className="mrb-0">
                        { currentLanguage == 'en' ? props.data.kitchener.email : props.data.kitchener.email }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="contact-block d-flex mrb-30">
                    <div className="contact-icon">
                      <i className="base-icon-phone-2"></i>
                    </div>
                    <div className={`contact-details ${currentLanguage == 'ar' ? 'mrl-30': 'mrr-30'}`}>
                      <h5 className="icon-box-title mrb-10">
                        {t('general.callUs')}
                      </h5>
                      <p className="mrb-0" dir="ltr">
                        { currentLanguage == 'en' ? props.data.kitchener.phone : props.data.kitchener.phone }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="contact-form">
                {loading ? (<Form
                  onFinish={onFinish}
                  size="large"
                  labelCol={{
                    span: 7,
                  }}
                  wrapperCol={{
                    span: 15,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                >
                  <div className="row mrb-10">
                    {/* Item */}
                    <div className="col-md-6">
                      <div className="mrb-20">
                        <Form.Item
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: t('validation.required', {field: t('form.name')}),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('form.name')}
                            className="w-100 form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="col-md-6">
                      <div className="mrb-20">
                        <Form.Item name="phone" rules={[{ validator }]}>
                          <PhoneInput enableSearch />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="col-md-12">
                      <div className="mrb-20">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t('validation.required', {field: t('form.email')}),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('form.email')}
                            className="w-100 form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="col-lg-12">
                      <div className="mrb-20">
                        <Form.Item
                          name="message"
                          rules={[
                            {
                              required: true,
                              message: t('validation.required', {field: t('form.message')}),
                            },
                          ]}
                        >
                          <TextArea
                            placeholder={t('form.message')}
                            rows="5"
                            className="w-100 form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-grid gap-2 mrb-0">
                      <button
                        type="submit"
                        className="animate-btn-style3 btn-md mrb-lg-60"
                      >
                        {t('form.submit')}
                      </button>
                    </div>
                  </div>
                </Form>
                ): <>
                  <Spin size="large" />
                </>}
              </div>
            </div>
            <div className="col-xl-6">
              {/* <!-- Google Map Start --> */}
              <div className="mapouter fixed-height">
                <div className="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1726.6712424793593!2d31.344545620599355!3d30.055715964017715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e7a7d2a26c1%3A0xc114352b3ff9da34!2s22%20Makram%20Ebeid%2C%20Al%20Manteqah%20as%20Sadesah%2C%20Nasr%20City%2C%20Cairo%20Governorate%204450309!5e0!3m2!1sen!2seg!4v1704732839130!5m2!1sen!2seg"
                          allowFullScreen loading="lazy" ></iframe>
                  <Link to="https://www.whatismyip-address.com/"></Link>
                </div>
              </div>
              {/* <!-- Google Map End --> */}
            </div>
          </div>
        </div>
      </section>
      <Footer
          data={props.data}
          urls={props.services}
      />
    </>
  );
};

export default ContactUs;
