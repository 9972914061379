import React, {useContext, useEffect, useState} from "react";
import PageTitle from "./PageTitle";

import {Link, useParams} from "react-router-dom";
import Footer from "../../layout/Footer";
import {Image} from "antd";
import LanguageContext from "../../../LanguageContext";
import {useTranslation} from "react-i18next";
import {get} from "../../api/axios";
import Preloader from "../../layout/Preloader";
import {CalendarOutlined, ClockCircleOutlined} from "@ant-design/icons";
import useFetchAllData from "../../api/data/fetchAllData";
import fetchAllData from "../../api/data/fetchAllData";

const Article = (props) => {
  const {id} = useParams();
  const {currentLanguage} = useContext(LanguageContext);
  const {t} = useTranslation();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchGeneralData = useFetchAllData();
  const [featuredArticles, setFeaturedArticles] = useState([]);

  useEffect(() => {
    get(`articles/${id}`)
        .then(response => {
          setArticle(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching post:', error);
          setLoading(true);
        });
  }, [id]);

  useEffect(() => {
    setFeaturedArticles(fetchGeneralData.articleFeatured);
  }, [fetchGeneralData]);

  return (
      loading ?
          <Preloader/> :
          <>
            <PageTitle title={currentLanguage == 'ar' ? article.title.ar : article.title.en} />
            <section className="blog-single-news pdt-110 pdb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="single-news-details news-wrapper mrb-20">
                <div className="single-news-content">
                  <div className="news-thumb">
                    <Image
                        preview={false}
                        width="100%"
                        className="img-full"
                        src={article.image.src}
                        alt={article.image.alt}
                    />
                    <div className="news-top-meta">
                      <span className="entry-category">
                        {currentLanguage == "ar" ? article.category.name.ar : article.category.name.en}
                      </span>
                    </div>
                  </div>
                  <div className="news-description mrb-35">
                    <h4 className="the-title">
                      {/*<Link to="page-news-details.html">*/}
                      {currentLanguage == "ar" ? article.title.ar : article.title.en}
                      {/*</Link>*/}
                    </h4>
                    <div className="news-bottom-part">

                      <div className="post-link">
                        <span className="entry-date">
                          <CalendarOutlined
                              className={`text-primary-color ${currentLanguage == 'ar' ? 'mrr-10' : 'mrl-10'}`}/>
                          {article.published_date_formated}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry-content">
                    <blockquote className="block-quote">
                      <p>
                        {currentLanguage == "ar" ? article.content.ar : article.content.en}
                      </p>
                      <span className="text-primary-color">- Kitchener</span>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 sidebar-right">
              <aside className="news-sidebar-widget">
                <div className="widget sidebar-widget widget-popular-posts">
                  <h4 className="mrb-30 single-blog-widget-title">
                    {currentLanguage == 'en' ? 'Popular Posts' : 'المقالات الشائعة'}
                  </h4>
                  {
                    featuredArticles.map((article, index) => {
                      return (
                          <div key={index} className="single-post mrb-20">
                            <Link to={`/blog/${article.slug}`} className="d-flex align-items-center ">
                              <div className="post-image mrr-20 w-25">
                                <Image preview={false} src={article.image.src} alt={article.image.alt}/>
                              </div>
                              <div className="post-content w-75">
                              <span className="post-date">
                                <ClockCircleOutlined className="mrr-5"/> {article.published_date_formated}
                              </span>
                                <h5>
                                  {currentLanguage == 'ar' ? article.title.ar : article.title.en}
                                </h5>
                              </div>
                            </Link>
                          </div>
                      );
                    })
                  }
                </div>
                {/*<div className="widget sidebar-widget widget-tags">*/}
                {/*  <h4 className="mrb-15 single-blog-widget-title">Tags</h4>*/}
                {/*  <ul className="list">*/}
                {/*    <li>*/}
                {/*      <Link to="#">Interior</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Architecture</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Design</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Building</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Kitchen</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">3D Model</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Room</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <Link to="#">Lighting</Link>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
              </aside>
            </div>
          </div>
        </div>
      </section>
            <Footer data={props.data} urls={props.services}/>
          </>
  );
};

export default Article;
