import React, {useContext} from 'react';
import { Fade } from 'react-awesome-reveal';
import LanguageContext from "../../LanguageContext";
import {useTranslation} from "react-i18next";

const WorkFlow = () => {
  const {t} = useTranslation();
  return (
    <section className="working-process-section bg-secondary-color bg-no-repeat bg-cover bg-pos-cb pdt-300 pdb-105"
      data-overlay-dark="2">
      <Fade className="section-title text-center fadeInUp" cascade direction='up' delay="0" duration="1500">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col-md-12 col-lg-10 col-xl-6">
              <div className="title-box">
                <h5 className="text-primary-color mrb-10">
                  {t('home.workflow.title')}
                </h5>
                <h2 className="text-white mrb-10">
                    {t('home.workflow.subtitle')}
                </h2>
                <div className="big-text">
                    {t('home.workflow.master_word')}
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </Fade>
      <div className="section-content">
        <div className="container">
          <div className="working-process-wrapper">
            <div className="working-process-shape">
              <img src="/assets/img/bg/working-process-shape.png" alt="" />
            </div>
            <div className="row">

              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="process-item mrb-md-40 mrb-sm-0">
                  <div className="process-icon-box">
                    <div className="process-icon">
                      <span className="webexflaticon webextheme-icon-interior"></span>
                    </div>
                    <div className="process-count"></div>
                  </div>
                  <div className="process-details">
                    <h4 className="process-title">
                      {t('home.workflow.steps.step_1.title')}
                    </h4>
                    <p className="process-text">
                      {t('home.workflow.steps.step_1.description')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="process-item mrb-md-40">
                  <div className="process-icon-box">
                    <div className="process-icon">
                      <span className="webexflaticon webextheme-icon-measure"></span>
                    </div>
                    <div className="process-count"></div>
                  </div>
                  <div className="process-details">
                    <h4 className="process-title">
                      {t('home.workflow.steps.step_2.title')}
                    </h4>
                    <p className="process-text">
                      {t('home.workflow.steps.step_2.description')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="process-item mrb-md-40">
                  <div className="process-icon-box">
                    <div className="process-icon">
                      <span className="webexflaticon webextheme-icon-architect-1"></span>
                    </div>
                    <div className="process-count"></div>
                  </div>
                  <div className="process-details">
                    <h4 className="process-title">{t('home.workflow.steps.step_3.title')}</h4>
                    <p className="process-text">
                      {t('home.workflow.steps.step_3.description')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="process-item mrb-md-40">
                  <div className="process-icon-box">
                    <div className="process-icon">
                      <span className="webexflaticon webextheme-icon-office-1"></span>
                    </div>
                    <div className="process-count"></div>
                  </div>
                  <div className="process-details">
                    <h4 className="process-title">
                      {t('home.workflow.steps.step_4.title')}
                    </h4>
                    <p className="process-text">
                      {t('home.workflow.steps.step_4.description')}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkFlow;
