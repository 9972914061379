import React, {useContext, useEffect, useState} from "react";
import Footer from "../layout/Footer";
import PageTitle from "./PageTitle";

import { Link } from "react-router-dom";
import {ClockCircleOutlined, LeftOutlined} from "@ant-design/icons";
import {Image, Input, Pagination} from "antd";
import LanguageContext from "../../LanguageContext";
import {useTranslation} from "react-i18next";
import {get} from "../api/axios";
import {Button} from "antd";
import {Form} from "antd";
import useForm from "antd/lib/form/hooks/useForm";
import Preloader from "../layout/Preloader";
import useFetchAllData from "../api/data/fetchAllData";


const Blog = (props) => {
  const { currentLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const fetchGeneralData = useFetchAllData();
  const [articles, setArticles] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const [params, setParams] = useState({
    page: 1,
    limit: 12
  });
  const onFinish = (values) => {
    console.log('Received values:', values);
    setLoading(true);
    setParams({
      ...params,
      search: values.search
    });
    console.log('Params: ', params);
  };
  useEffect(() => {
    let url = 'articles?';
    for (const param in params) {
        url += `${param}=${params[param]}&`;
    }
    // console.log('URL: ', url);
    setLoading(true);
    get(url).then((data) => {
      setArticles(data.data);
      setPaginationData(data.meta);
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    get(`articles?limit=${params.limit}`).then((data) => {
      setArticles(data.data);
      setPaginationData(data.meta);
      setTotal(data.meta.total);
      setLoading(false);
    }).catch(error => {
        console.error('Error fetching articles:', error);
        setLoading(true);
    });
  }, []);

  useEffect(() => {
    setFeaturedArticles(fetchGeneralData.articleFeatured);
    setArticleCategories(fetchGeneralData.articleCategories);
  }, [fetchGeneralData]);
    const getArticlesByCategory = (categoryId) => {
      if(categoryId == 'all') {
        const { category_id, ...rest } = params;

        setParams(rest);
      }
      else {
          setParams({
            ...params,
            category_id: categoryId
          });
        }
      console.log(params);

    };
  const onChange = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setParams({
      ...params,
        page: pageNumber
    });
  };
  return (

        loading ? <Preloader/> :
            <>
              <PageTitle/>
              <div className="blog-details-page pdt-110 pdb-70">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-7">
                      <div className="row">
                        {
                          articles.map((article, index) => {
                            return (
                                <div key={index} className="col-lg-12 col-xl-6">
                                  <div className="news-wrapper mrb-30">
                                    <Link to={`/blog/${article.slug}`}>
                                      <div className="news-thumb">
                                        <Image
                                            preview={false}
                                            src={article.image.src ?? "/assets/img/news/01.jpg"}
                                            className="img-full"
                                            alt={article.image.alt ?? "blog"}
                                        />
                                        <div className="news-top-meta">
                            <span
                                className="entry-category">{currentLanguage == 'ar' ? article.category.name.ar : article.category.name.en}</span>
                                        </div>
                                      </div>
                                      <div className="news-description">
                                        <h4 className="the-title">
                                          {currentLanguage == 'ar' ? article.title.ar : article.title.en}
                                        </h4>
                                        <p className="the-content">
                                          {currentLanguage == 'ar' ? article.short_description.ar : article.short_description.en}
                                        </p>
                                        <div className="news-bottom-part">
                                          {/*<div className="post-author">*/}
                                          {/*  <div className="author-img">*/}
                                          {/*    /!*<Link to={`/${article.slug}`}>*!/*/}
                                          {/*    /!*  <Image*!/*/}
                                          {/*    /!*      preview={false}*!/*/}
                                          {/*    /!*      className={"rounded-circle"}*!/*/}
                                          {/*    /!*      src={props.data.kitchener.logo}*!/*/}
                                          {/*    /!*  />*!/*/}
                                          {/*    /!*</Link>*!/*/}
                                          {/*  </div>*/}
                                          {/*  <span>*/}
                                          {/*    <Link to="/">Kitchener</Link>*/}
                                          {/*  </span>*/}
                                          {/*</div>*/}
                                          <div className="post-link">
                              <span className="entry-date">
                                <i className="far fa-calendar-alt mrr-10 text-primary-color"></i>
                                {article.published_date_formated}
                              </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                            );
                          })
                        }
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <Pagination
                              responsive={true}
                              total={paginationData.total}
                              pageSize={paginationData.per_page}
                              showTotal={(total) => `${paginationData.from} - ${paginationData.to} ${currentLanguage == 'ar' ? 'من' : 'of'} ${total} ${currentLanguage == 'ar' ? 'مقال' : 'Articles'}`}
                              onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 sidebar-right">
                      <div className="news-sidebar-widget">
                        <div className="widget sidebar-widget widget-search mrb-20">
                          <Form
                              form={form}
                              onFinish={onFinish}
                              size='large'
                              labelCol={{
                                span: 7,
                              }}
                              wrapperCol={{
                                span: 15,
                              }}
                              initialValues={{
                                remember: true,
                              }}
                              autoComplete="off"
                              className="search-form"
                          >
                            <Form.Item
                                className="search-field"
                                name="search"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                            >
                              <Input placeholder={t('form.search')} className="w-100 form-control"/>
                            </Form.Item>

                            <button>
                              <i className="base-icon-search-1"></i>
                            </button>
                          </Form>
                        </div>
                        <div className="widget sidebar-widget widget-categories">
                          <h4 className="mrb-20 single-blog-widget-title">
                            {currentLanguage == 'en' ? 'Categories' : 'التصنيفات'}
                          </h4>
                          <ul className="list">
                            <li>
                              <LeftOutlined className="vertical-align-middle text-primary-color mrr-10"/>
                              <Link to="#" onClick={event => getArticlesByCategory('all')}>
                                {currentLanguage == 'ar' ? 'الكل' : 'All'}
                              </Link>
                              <span className="f-right">({total})</span>
                            </li>
                            {
                              articleCategories.map((category, index) => {
                                return (
                                    <li key={index}>
                                      <LeftOutlined className="vertical-align-middle text-primary-color mrr-10"/>
                                      <Link to="#" onClick={event => getArticlesByCategory(category.id)}>
                                        {currentLanguage == 'ar' ? category.name.ar : category.name.en}
                                      </Link>
                                      <span className="f-right">({category.articles_count})</span>
                                    </li>
                                );
                              })
                            }
                          </ul>
                        </div>
                        <div className="widget sidebar-widget widget-popular-posts">
                          <h4 className="mrb-30 single-blog-widget-title">
                            {currentLanguage == 'en' ? 'Popular Posts' : 'المقالات الشائعة'}
                          </h4>
                          {
                            featuredArticles.map((article, index) => {
                              return (
                                  <div key={index} className="single-post mrb-20">
                                    <Link to={`/blog/${article.slug}`} className="d-flex align-items-center ">
                                      <div className="post-image mrr-20 w-25">
                                        <Image preview={false} src={article.image.src} alt={article.image.alt}/>
                                      </div>
                                      <div className="post-content w-75">
                              <span className="post-date">
                                <ClockCircleOutlined className="mrr-5"/> {article.published_date_formated}
                              </span>
                                        <h5>
                                          {currentLanguage == 'ar' ? article.title.ar : article.title.en}
                                        </h5>
                                      </div>
                                    </Link>
                                  </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer
                  data={props.data}
                  urls={props.services}
              />
            </>

  );
};

export default Blog;
