import {
    ExportOutlined,
    FacebookOutlined,
    InstagramOutlined,
    MailOutlined,
    PhoneOutlined,
} from "@ant-design/icons";
import React, {useContext} from "react";
import { Link } from "react-router-dom";
import {Image} from "antd";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";

const Footer = (props) => {
    let kitchenerData = props.data.kitchener;
    let socialLinks = props.data.socialLinks;
    const {t} = useTranslation();
    const  {currentLanguage} = useContext(LanguageContext);
    return (
        <footer className="footer bg-cover" data-overlay-dark="98">
            <div className="footer-main-area">
                <div className="footer-section-obj1">
                    <img src="/assets/img/objects/footer-obj1.png" alt={currentLanguage == 'en' ? kitchenerData.name.en : kitchenerData.name.ar} />
                </div>
                <div className={`footer-section-obj2 ${currentLanguage == 'ar' ? 'rtl': 'ltr'}`}>
                    <img src="/assets/img/objects/footer-obj2.png" alt={currentLanguage == 'en' ? kitchenerData.name.en : kitchenerData.name.ar} />
                </div>
                <div className="container">
                    <div className="row pdb-65">
                        <div className="col-md-6">
                            <div className="widget footer-widget mrr-60 mrr-md-0">
                                <div className="footer-logo">
                                    <Image
                                        preview={false}
                                        width={350}
                                        alt={currentLanguage == 'en' ? kitchenerData.name.en : kitchenerData.name.ar}
                                        src={kitchenerData.logo ?? '/assets/img/logo-black.png'}
                                        className={"mrb-25"}
                                    />
                                </div>
                                <p className="mrb-25">
                                    {currentLanguage == 'en' ? kitchenerData.description.en : kitchenerData.description.ar }
                                </p>
                                <ul className="social-list">
                                    {socialLinks.facebook && <>
                                        <li>
                                            <Link to={socialLinks.facebook}>
                                                <FacebookOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.instagram && <>
                                        <li>
                                            <Link to={socialLinks.instagram}>
                                                <InstagramOutlined/>
                                            </Link>
                                        </li>
                                    </>
                                    }
                                    {socialLinks.tiktok && <>
                                        <li>
                                            <Link to={socialLinks.tiktok}>
                                                <ExportOutlined/>
                                            </Link>
                                        </li>

                                    </>}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="widget footer-widget">
                            <h5 className={`widget-title text-white mrb-30 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                                {t('footer.services')}
                            </h5>
                                <ul className={`footer-widget-list ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                                    {
                                        props.urls.map((service, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={`/services/${service.slug}`}>
                                                        {currentLanguage === 'ar' ? service.name.ar : service.name.en}
                                                    </Link>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="widget footer-widget mrr-30 mrr-md-0">
                                <h5 className={`widget-title text-white mrb-30 ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                                    {t('footer.about')}
                                </h5>
                                <address className="mrb-0">
                                    <p>
                                        {currentLanguage == 'en' ? kitchenerData.address.en : kitchenerData.address.ar }
                                    </p>
                                    <div className="mrb-10">
                                        <Link to={`tel:${kitchenerData.phone}`} className="contacts-list">
                                            <PhoneOutlined />
                                            {kitchenerData.phone}
                                        </Link>
                                    </div>
                                    <div className="mrb-10">
                                        <Link to={`mailto:${kitchenerData.email}`} className="contacts-list">
                                            <MailOutlined />
                                            {kitchenerData.email}
                                        </Link>
                                    </div>
                                </address>
                            </div>
                        </div>
                    </div>
                    <div className="row pdt-30 pdb-30 footer-copyright-area">
                        <div className="col-xl-12">
                            <div className="text-center">
                                <span>{currentLanguage == 'en' ? kitchenerData.copyright.en : kitchenerData.copyright.ar}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
