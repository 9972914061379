import React, {Component} from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "./CarouselArrows";
import CarouselSLide from "./CarouselSLide";
import {withTranslation} from "react-i18next";

export class HomeCarousel extends Component {
  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 3000,
      slidesToShow: 1,
      autoplay: false,
      initialSlide: 0,
      fade: true,
      prevArrow: <PrevArrow />, // Use your custom LeftArrow component
      nextArrow: <NextArrow />,
      adaptiveHeight: true,
    };

    return (
      <section className="home-carousel">
        <Slider
            {...settings}
        >
          { this.props.slides.map((slide, index) => {
            return (
                <CarouselSLide
                    key={index}
                    slide={slide}
                />
            );
          })}
        </Slider>
      </section>
    );
  }
}

export default withTranslation()(HomeCarousel);
