import React, {useContext} from "react";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../LanguageContext";

const PageTitle = () => {
  const {t} = useTranslation();
  const {currentLanguage} = useContext(LanguageContext);
  return (
    <>
      <section className="page-title-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="breadcrumb-area">
                <h2 className="page-title">{t('menu.about')}</h2>
                <ul className={`breadcrumbs-link ${ currentLanguage == 'ar' ? 'rtl' : 'ltr' }`}>
                  <li>
                    <Link to="/">{t('menu.home')}</Link>
                  </li>
                  <li className="active">{t('menu.about')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageTitle;
