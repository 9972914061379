import React from 'react';
import ReactCompareImage from 'react-compare-image';

const CompareImage = (props) => {
  return (
    <section className="service-section-style bg-no-repeat bg-cover bg-pos-cb">
      <div className="section-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">

              <div className="before-after-slider1">
                <ReactCompareImage
                  leftImage={props.images.after ?? '/assets/img/before-after/1.jpg'}
                  leftImageLabel="Before"
                  rightImage={props.images.before ?? '/assets/img/before-after/2.jpg'}
                  rightImageLabel="After"
                  sliderLineWidth="4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompareImage;
