import React from "react";
import './App.scss';
import { useEffect, useState } from 'react';
import {BrowserRouter} from 'react-router-dom';
import LayoutComponent from './components/layout/layout';
import Preloader from './components/layout/Preloader';
import {ConfigProvider} from "antd";
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import { useTranslation } from 'react-i18next';
import useFetchAllData from "./components/api/data/fetchAllData";

function App() {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
    const fetchGeneralData = useFetchAllData();

    useEffect(() => {
        if (fetchGeneralData && fetchGeneralData.homeIsLoading === false) {
            setLoading(false);
        }
        else {
            console.log('error with fetchGeneralData');
        }
  }, [fetchGeneralData.homeIsLoading]);
  return (
      <React.StrictMode>
        <ConfigProvider
            locale={i18n.language === 'ar' ? arEG : enUS}
            direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            theme={{
              token: {
                fontFamily: "'Rubik', sans-serif"
              },
            }}
        >
          <BrowserRouter>
          {loading ? (<Preloader />) : (<LayoutComponent data={fetchGeneralData} />)}
        </BrowserRouter>
        </ConfigProvider>
      </React.StrictMode>
  );
}

export default App;
